import 'moment-timezone';
import * as moment from 'moment';
import Box from '@material-ui/core/Box';
import BugReport from '@material-ui/icons/BugReport';
import BugReportOutlined from '@material-ui/icons/BugReportOutlined';
import Container from '@material-ui/core/Container';
import DatePicker from 'react-datepicker';
import EnvCoreHours from './EnvCoreHours.js';
import IconButton from '@material-ui/core/IconButton';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import "react-datepicker/dist/react-datepicker.css";
import './App.css';

const environments = {
  'NA': ['America/New_York', 'America/Los_Angeles'],
  'EU': ['Europe/London', 'Europe/Helsinki'],
  'AP': ['Asia/Bangkok', 'Asia/Hong_Kong', 'Pacific/Auckland'],
  'AU': ['Pacific/Auckland', 'Australia/Sydney', 'Australia/Perth'],
  'CA': ['America/Halifax', 'America/Toronto', 'America/Vancouver'],
  'JP': ['Asia/Tokyo', 'Asia/Seoul'],
};

class CoreHours extends React.Component {
  constructor(props) {
    super(props);

    this.enableDebug = this.enableDebug.bind(this);
    this.disableDebug = this.disableDebug.bind(this);
    this.handleTimeSelection = this.handleTimeSelection.bind(this);
    let now = moment();

    this.state = {
      currentTime: now,
      selectedTime: now,
      debug: false,
      picked: false,
    };
  }

  handleTimeSelection(date) {
    let picked = true;
    let selectedTime = moment(date);
    if (date === null) {
      picked = false;
      selectedTime = moment();
    }
    this.setState({
      selectedTime: selectedTime,
      picked: picked,
    });
  }

  enableDebug() {
    this.resetTimer(1000);
    this.setState({
      currentTime: moment(),
      debug: true,
    });
  }
  disableDebug() {
    this.resetTimer();
    this.setState({
      currentTime: moment(),
      debug: false,
    });
  }

  tick() {
    let now = moment();
    let state = {
      currentTime: now
    };
    if (!this.state.picked) {
      state['selectedTime'] = now;
    }
    this.setState(state);
  }

  setTimer(interval) {
    if (interval === undefined) {
      interval = this.state.debug ? 1000 : 10000
    }
    this.timerID = setInterval(
      () => this.tick(),
      interval
    );
  }

  unsetTimer() {
    clearInterval(this.timerID);
  }

  resetTimer(interval) {
    this.unsetTimer();
    this.setTimer(interval);
  }

  componentDidMount() {
    this.setTimer();
  }

  componentWillUnmount() {
    this.unsetTimer();
  }

  render() {
    const clocks = [];
    for (let env in environments) {
      clocks.push(<EnvCoreHours
        key={env}
        env={env}
        regionTimeZones={environments[env]}
        date={this.state.selectedTime}
        debug={this.state.debug}
        />);
    }
    let bugReport = this.state.debug ?
      <BugReportOutlined /> :
      <BugReport />
    return (
      <Container>
        <Box color='primary' m={1} p={1}>
          <Box color='primary' m={1} p={1}>
            <Typography align='center' variant='h4'>
              You are in the "{moment.tz.guess()}" timezone.
            </Typography>
          </Box>
          <Box color='primary' m={1} p={1}>
            <Typography align='center' m={1} p={1}>
              It is currently {this.state.currentTime.format('LLLL')}.
            </Typography>
          </Box>
          <Box color='primary' m={1} p={1} align="center">
            <Typography>
              Select another date/time (or clear for the current time)
              &nbsp;&nbsp;&nbsp;&nbsp;
            </Typography>
            <div className="fixedWidthDatePicker">
              <div className="customDatePicker">
                <DatePicker
                    selected={this.state.selectedTime.toDate()}
                    onChange={this.handleTimeSelection}
                    dateFormat="yyy-MM-dd HH:mm"
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={30}
                    isClearable={true}
                    placeholderText="Click to select a date/time."
                />
              </div>
            </div>
            <Typography>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Tooltip title="Debug">
                <IconButton
                  align="right"
                  aria-label="debug"
                  onClick={this.state.debug ? this.disableDebug : this.enableDebug}
                >
                  {bugReport}
                </IconButton>
              </Tooltip>
            </Typography>
          </Box>
        </Box>
        <Box align="right">
        </Box>
        <Box color='primary' align="center">
          {clocks}
        </Box>
      </Container>
    );
  }
}

export default CoreHours;
