import React from 'react';
import * as moment from 'moment';
import 'moment-timezone';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

// We define the SOB as 7am
const EnvLocalStart = 7;
// We define the EOB as 5pm
const EnvLocalEnd = 17;
const RED = '#dc322f';
const GREEN = '#859900';

class EnvCoreHours extends React.Component {

    getMondayTime = (dt, hour) => {
        let mondayTime = moment(dt).add(1, 'weeks').isoWeekday(1);
        return this.getSpecifiedHour(mondayTime, hour);
    }

    getSpecifiedHour = (dt, hour) => {
        return moment(dt).hour(hour).minute(0).second(0).millisecond(0);
    }

    render() {
        let inHours = false;
        let nextInterestingStartTime = moment(this.props.date).add(1, 'weeks');
        let nextInterestingEndTime = moment(this.props.date).subtract(1, 'weeks');
        let tzTimes = []
        this.props.regionTimeZones.forEach((timezone) => {
            console.log('Timezone: ' + timezone);
            let tzInHours = false;
            let tzTime = moment(this.props.date).tz(timezone);
            let envStart;
            let envEnd;
            let dayOfWeek = tzTime.isoWeekday();
            // ISO day of the week is 1-7, 1-Monday, 7-Sunday
            // weekends are never in hours
            if (dayOfWeek > 5) {
                // For weekends, Monday is always the next interesting time
                console.log('in weekend');
                envStart = this.getMondayTime(tzTime, EnvLocalStart);
                envEnd = this.getSpecifiedHour(tzTime, EnvLocalEnd);
            }
            else if (tzTime.hour() < EnvLocalStart) {
                console.log('before hours');
                envStart = this.getSpecifiedHour(tzTime, EnvLocalStart);
                envEnd = this.getSpecifiedHour(tzTime, EnvLocalEnd);
            }
            else if (tzTime.hour() >= EnvLocalEnd) {
                console.log('after hours');
                // Special case Friday's after hours
                if (5 === tzTime.isoWeekday()) {
                    console.log('Friday after hours');
                    envStart = this.getMondayTime(tzTime, EnvLocalStart);
                    envEnd = this.getSpecifiedHour(tzTime, EnvLocalEnd);
                }
                else {
                    console.log('weekday after hours');
                    envStart = this.getSpecifiedHour(moment(tzTime).add(1, 'days'), EnvLocalStart);
                    envEnd = this.getSpecifiedHour(tzTime, EnvLocalEnd);
                }
            }
            else {
                console.log('weekday in hours' + tzTime.format());
                inHours = true;
                tzInHours = true;
                envStart = this.getSpecifiedHour(tzTime, EnvLocalStart);
                envEnd = this.getSpecifiedHour(tzTime, EnvLocalEnd);
            }
            let localStart = envStart.tz(moment.tz.guess());
            let localEnd = envEnd.tz(moment.tz.guess());
            if (localStart < nextInterestingStartTime) {
                nextInterestingStartTime = localStart;
            }
            if (localEnd > nextInterestingEndTime) {
                nextInterestingEndTime = localEnd;
            }
            tzTimes.push(
                <Box key={timezone} m={1} bgcolor={tzInHours ? RED : GREEN} >
                    <Typography key="{timezone}" bgcolor={tzInHours ? RED : GREEN}>
                        In {timezone} it is {tzTime.format()}, {tzInHours ? 'IN' : 'OUTSIDE'} hours.
                    </Typography>
                    <Typography key="{timezone}-start" pl={1}>
                        Start time is {envStart.format()}.
                    </Typography>
                    <Typography key="{timezone}-end" pl={1}>
                        End time is {envEnd.format()}.
                    </Typography>
                </Box>
            );
        });
        let nextInterestingTime = inHours ? nextInterestingEndTime : nextInterestingStartTime;
        let countdown = moment(this.props.date).to(nextInterestingTime);
        let color = inHours ? RED : GREEN
        return (
            <Box bgcolor={color} m={1} p={1} width={this.props.debug ? '90%' : '75%'}>
                <Typography>
                    In {this.props.env} it is {inHours ? 'IN' : 'OUTSIDE'} core hours.
                </Typography>
                <Typography>
                    Core hours will {inHours ? 'END' : 'START'} {countdown}, {nextInterestingTime.calendar()}.
                </Typography>
                {this.props.debug ? tzTimes : ''}
            </Box>
        );
    }

}

export default EnvCoreHours;
