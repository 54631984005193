import React from 'react';
import './App.css';
import CoreHours from './CoreHours.js';
import 'moment-timezone';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

const base3 = '#fdf6e3';
// const base2 = '#eee8d5';
const base00 = '#657b83';
// const green = '#859900';
// const red = '#dc322f';
const theme = createTheme({
  palette: {
    primary: {
      main: base00,
      background: base3,
    },
  },
});

class App extends React.Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <CoreHours />
      </ThemeProvider>
    );
  }
}

export default App;
